<template>
    <loading-animation class="card bs-4" v-if="DataLoading"></loading-animation>
    <div v-else class="pl-3 pr-3">
        <div v-if="data" class="fl-te-c mb-3">
            <h5 class="mb-2">Attendance <span class="text-primary">{{ data.date }}</span></h5>
            <div class="btn-group">
                <btn size="sm" text="Submit" @click="submitClicked" :loading="loading"
                     loading-text="Submitting..."></btn>
            </div>
        </div>
        <div v-if="data" class="mt-2">
            <custom-simple-table class="table-borderless bs-4 round-2 of-h" v-if="data.users" :data="data.users" :fields="fields" ref="table">
                <template #actions="{rowData}">
                    <validated-vue-select size="md" class="mb-0" :options="attendanceOptions"
                                          name="Attendance" :rules="{required: false}"
                                          v-model="rowData.attendance"
                                          :disabled="loading">
                    </validated-vue-select>
                </template>
            </custom-simple-table>
        </div>
        <div v-if="!data" class="card bg-primary bs-4 text-center">No data available.</div>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
// import axios from 'secure-axios';
let today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

export default {
    name : 'DailyAttendance',
    data () {
        return {
            loading     : false,
            DataLoading : false,
            attendance  : null,
            data        : {
                users : null,
                date  : today
            },
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'email',
                    sortField : 'email',
                    title     : 'E-Mail'
                },
                {
                    name      : 'role',
                    sortField : 'role',
                    title     : 'Role'
                },
                {
                    name  : '__slot:actions',
                    title : 'Attendance'
                }

            ],
            attendanceOptions : [
                { value : 'Present', label : 'Present' },
                { value : 'Half Day', label : 'Half Day' },
                { value : 'Full Day', label : 'Full Day' },
                { value : '1 Hr', label : '1 Hr' },
                { value : '2 Hr', label : '2 Hr' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.hrAdmin.attendance.attendanceUpdate, {
                attendance : JSON.stringify(that.data)
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Updated Successfully', 'Success', {
                    type : 'success'
                });
                that.loading = false;
            } else if (json.errors) {
                that.$notify(json.errors.__all__, 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to update details..Please try again later!', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.get(urls.hrAdmin.attendance.attendanceList);
            const json = response.data;
            if (json.data) {
                that.data.users = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('No details available', 'Message', {
                    type : 'info'
                });
                that.DataLoading = false;
            }
        }
    }
};
</script>

<style>
.attendance-table th {
}
</style>
